<template>
  <v-form @submit.prevent="handleSubmitForm" class="user-form">
    <v-card :loading="form_loading">
      <v-toolbar color="primary" class="white--text">
        <v-toolbar-title>
          <v-icon>mdi-person</v-icon>
          {{ getCardTitle() }}
          <span
            v-if="
              !$store.getters['auth/getScope']('auth.users.edit') && !!form.id
            "
            >{{ $capitalize($tc("model.users_read_only")) }}</span
          >
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="handleCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs dark>
            <v-tab>{{ $capitalize($tc("model.users_users_data")) }}</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card-text>
        <ValidationObserver ref="forms" v-slot="{ invalid }">
          <v-alert type="error" class="mt-2" v-if="form_message">{{
            form_message
          }}</v-alert>
          <v-container>
            <ValidationProvider
              name="name"
              rules="required|max:45"
              v-slot="{ errors }"
            >
              <v-text-field
                :label="$capitalize($tc('model.users_full_name'))"
                name="name"
                type="text"
                :counter="45"
                v-model="form.name"
                :error-messages="errors"
                :disabled="form_loading"
                :readonly="
                  !$store.getters['auth/getScope']('auth.users.edit') && form.id
                "
              />
            </ValidationProvider>
            <ValidationProvider
              name="username"
              rules="required|max:45"
              v-slot="{ errors }"
            >
              <v-text-field
                :label="$capitalize($tc('model.users_username'))"
                name="username"
                type="text"
                :counter="45"
                v-model="form.username"
                :error-messages="errors"
                :disabled="form_loading"
                :readonly="
                  !$store.getters['auth/getScope']('auth.users.edit') && form.id
                "
              />
            </ValidationProvider>
            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <v-text-field
                :label="$capitalize($tc('model.users_email'))"
                name="email"
                type="email"
                :counter="100"
                v-model="form.email"
                :error-messages="errors"
                :disabled="form_loading"
                :readonly="
                  !$store.getters['auth/getScope']('auth.users.edit') && form.id
                "
              />
            </ValidationProvider>
            <ValidationProvider name="phone_number" rules v-slot="{ errors }">
              <v-text-field
                :label="$capitalize($tc('model.users_contact'))"
                name="contacto"
                :counter="20"
                v-model="form.phone_number"
                :error-messages="errors"
                :disabled="form_loading"
                :readonly="
                  !$store.getters['auth/getScope']('auth.users.edit') && form.id
                "
              />
            </ValidationProvider>
            <ValidationProvider
              name="password"
              :rules="form.id ? '' : 'required|min:6'"
              v-slot="{ errors }"
            >
              <v-text-field
                id="password"
                :label="$capitalize($tc('model.users_password'))"
                name="password"
                v-model="form.password"
                :error-messages="errors"
                :disabled="form_loading"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                :hint="
                  id ? $capitalize($tc('model.users_message_password')) : ''
                "
                persistent-hint
              />
            </ValidationProvider>
            <ValidationProvider
              name="can_access_portal_hcm"
              :rules="'required'"
              v-slot="{ errors }"
            >
              <v-switch
                id="password"
                label="Acesso ao Portal HCM"
                name="can_access_portal_hcm"
                v-model="form.can_access_portal_hcm"
                :error-messages="errors"
                :disabled="form_loading"
              />
            </ValidationProvider>
          </v-container>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions
        v-if="
          $store.getters['auth/getScope']('auth.users.edit') ||
          $store.getters['auth/getScope']('auth.users.add')
        "
      >
        <v-spacer></v-spacer>
        <v-btn @click="handleCancel" text :disabled="form_loading">{{
          $capitalize($tc("model.users_button_cancel"))
        }}</v-btn>
        <v-btn type="submit" color="primary" :loading="form_loading">{{
          $capitalize($tc("model.users_button_save"))
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { show, store, update } from "../../services/users";

export default {
  name: "user-form",
  model: {
    prop: "id",
    event: "change",
  },
  props: ["id"],
  data: () => ({
    form: {
      is_master: false,
    },
    show: false,
    form_loading: false,
    form_message: "",
  }),
  methods: {
    getCardTitle() {
      return this.id
        ? this.form.name
        : this.$capitalize(this.$tc("model.users_card_title_new_user"));
    },
    handleCancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    resetForm() {
      this.form = {
        is_master: false,
      };
      this.$refs.forms.reset();
    },
    async findUser(id) {
      this.form_loading = true;
      try {
        const response = await show(id);
        this.form = response.user;
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: e.message,
        });
      } finally {
        this.form_loading = false;
      }
    },
    async handleSubmitForm() {
      const validation = await this.$refs.forms.validate();

      if (validation) {
        const payload = {
          ...this.form,
          phone_number: this.form.phone_number ? this.form.phone_number : null,
        };
        this.form_loading = true;
        try {
          let response = null;
          let message = "Registro atualizado";
          if (this.id) {
            await update(this.id, payload);
          } else {
            message = "Registro criado com sucesso";
            await store(payload);
          }
          this.resetForm();
          this.$emit("submit");
          this.$store.commit("sendMessage", {
            text: message,
          });
        } catch (e) {
          this.$refs.forms.setErrors(e.response.data.errors);
          this.$store.commit("sendMessage", {
            text: `(${e.response.status}) ${e.response.data.message}`,
            color: "red",
          });
        } finally {
          this.form_loading = false;
        }
      }
    },
  },
  created() {
    if (this.id == null) {
    } else {
      this.findUser(this.id);
    }
  },

  watch: {
    id: function (value, old_value) {
      if (value == null) {
        this.resetForm();
      } else {
        this.findUser(value);
      }
    },
  },
};
</script>

<style lang="scss">
.user-form {
}
</style>
