<template>
  <container class="users">
    <v-card>
      <v-card-title>
        {{ $capitalize($tc("model.users_user_title")) }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-text-field
              v-model="filter"
              :label="$capitalize($tc('model.users_search_users'))"
              prepend-icon="mdi-magnify"
              @keyup.enter="getUsers"
              :disabled="loading"
            ></v-text-field>
          </v-col>
          <v-col class="d-none d-sm-flex align-center justify-end">
            <v-btn icon @click="getUsers()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              v-if="$store.getters['auth/getScope']('auth.users.add')"
              @click="handleClickNewUser"
              color="primary"
              dark
            >
              {{ $capitalize($tc("model.users_new_user")) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="users"
        :items-per-page="5"
        :options.sync="options"
        :server-items-length="totalItems"
        class="elevation-1"
        :disabled="loading"
        :loading="loading"
        :loading-text="$capitalize($tc('model.users_loading'))"
        fixed-header
        max-height="500"
      >
        <template v-slot:header.name>
          {{ $capitalize($tc("model.users_name", 1)) }}
        </template>

        <template v-slot:header.username>
          {{ $capitalize($tc("model.users_username", 1)) }}
        </template>

        <template v-slot:header.role.name>
          {{ $capitalize($tc("model.users_function", 1)) }}
        </template>

        <template v-slot:header.phone_number>
          {{ $capitalize($tc("model.users_contact", 1)) }}
        </template>

        <template v-slot:header.email>
          {{ $capitalize($tc("model.users_email", 1)) }}
        </template>

        <template v-slot:header.actions>
          {{ $capitalize($tc("model.users_actions", 1)) }}
        </template>

        <template v-slot:item.avatar_url="{ item }">
          <user-avatar
            size="40"
            :user_name="item.name"
            :avatar_url="item.avatar_url"
            :user_id="item.id"
          />
        </template>
        <template v-slot:item.role.name="{ item }">
          <v-select
            :value="item.role_id"
            :items="roles"
            item-value="id"
            item-text="name"
            @change="changeRole(item, $event)"
            :loading="chanding_role_id.find(role_id => role_id == item.role_id)"
            solo
            flat
            hide-details
            v-if="$store.getters['auth/getScope']('auth.users.edit_role')"
          ></v-select>
          <span v-else-if="item.role">{{ item.role.name }}</span>
          <span v-else color="grey">{{
            $capitalize($tc("model.users_none"))
          }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click="handleClickEditUser(item.id)" icon>
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.getters['auth/getScope']('auth.users.delete')"
            @click="handleClickDelete(item.id)"
            icon
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      @click="handleClickNewUser"
      color="red"
      dark
      fab
      fixed
      bottom
      right
      class="d-sm-none"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="600px" persistent>
      <user-form
        @cancel="handleCloseDialog"
        @submit="handleSubmitForm"
        :id="selected_id"
      ></user-form>
    </v-dialog>

    <v-dialog v-model="confirmation" width="300px">
      <v-card>
        <v-card-title class="headline">
          {{ $capitalize($tc("model.users_confirming_deletion")) }}
        </v-card-title>

        <v-card-text>
          {{ $capitalize($tc("model.users_deletion_message")) }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="secondary"
            text
            @click="confirmation = false"
            :disabled="delete_loading"
            >{{ $capitalize($tc("model.users_button_no")) }}</v-btn
          >

          <v-btn
            color="primary"
            text
            @click="confirmDelete()"
            :loading="delete_loading"
            >{{ $capitalize($tc("model.users_button_yes")) }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </container>
</template>

<script>
import UserForm from "../components/forms/UserForm";
import { index, destroy, updateRole } from "../services/users";
import { index as indexRoles } from "../services/roles";
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "users",
  components: { UserForm, UserAvatar },
  data: () => ({
    dialog: false,
    filter: "",
    loading: false,
    headers: [
      {
        text: "",
        value: "avatar_url",
        sortable: false,
        align: "center",
        width: "70px"
      },
      {
        text: "Nome",
        value: "name",
        width: "300"
      },
      {
        text: "Username",
        value: "username"
      },
      {
        text: "Função",
        value: "role.name",
        sortable: false,
        width: "200"
      },
      {
        text: "Contacto",
        value: "phone_number"
      },
      {
        text: "Email",
        value: "email"
      },
      { text: "Ações", value: "actions", align: "right", sortable: false }
    ],
    users: [],
    selected_id: null,
    confirmation: false,
    delete_loading: false,
    roles: [],
    chanding_role_id: [],
    options: {
      itemsPerPage: 10,
      sortBy: ["name"],
      sortDesc: []
    },
    totalItems: 0
  }),
  methods: {
    async getUsers() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.loading = true;
      this.users = [];
      const response = await index({
        filter: this.filter || "",
        page,
        perPage: itemsPerPage,
        orderBy: sortBy[0] ? sortBy[0] : "",
        sortDesc: sortDesc[0] ? sortDesc[0] : ""
      });
      this.users = response.data;
      this.totalItems = response.total;
      this.loading = false;
    },
    handleClickNewUser() {
      this.selected_id = null;
      this.dialog = true;
    },
    handleSubmitForm() {
      this.selected_id = null;
      this.dialog = false;
      this.getUsers();
    },
    handleClickEditUser(id) {
      this.selected_id = id;
      this.dialog = true;
    },
    handleCloseDialog() {
      this.selected_id = null;
      this.dialog = false;
    },
    handleClickDelete(user_id) {
      this.confirmation = true;
      this.selected_id = user_id;
    },
    async confirmDelete() {
      this.delete_loading = true;
      try {
        await destroy(this.selected_id);
        this.confirmation = false;
        this.getUsers();
        this.$store.commit("sendMessage", {
          text: "Utilizador excluído com sucesso!"
        });
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: e.response.data.message,
          color: "red"
        });
      } finally {
        this.delete_loading = false;
      }
    },
    async changeRole(user, role_id) {
      this.chanding_role_id.push(role_id);
      try {
        await updateRole(user.id, role_id);
      } catch (error) {
      } finally {
        this.chanding_role_id.map(role => role !== role_id);
      }
    },
    async getRoles() {
      this.roles = await indexRoles();
    }
  },
  watch: {
    options: {
      handler() {
        this.getUsers();
      },
      deep: true
    },
    filter: function(value, old_value) {
      //Atualiza quando o utilizador limpar o filtro de busca
      if (value === null) this.getUsers();
    }
  },
  created() {
    this.getUsers();
    this.getRoles();
  }
};
</script>

<style lang="scss">
.users {
  margin-bottom: 100px;
}
</style>
