import Vue from "vue";


export async function index(filter = "") {
  const response = await Vue.prototype.$http.get("/roles", {
    params: {
      filter
    }
  });

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post("/roles", payload);

  return response.data;
}

export async function updateScopes(payload, id) {
  const response = await Vue.prototype.$http.post(
    "/roles/" + id + "/scopes",
    payload
  );

  return response.data;
}

export async function update(id, payload) {
  const response = await Vue.prototype.$http.put("/roles/" + id, payload);

  return response.data;
}

export async function destroy(id) {
  const response = await Vue.prototype.$http.delete("/roles/" + id);

  return response;
}
